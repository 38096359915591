import request from "./services/axios.js";
// 邮件推送-获取任务列表
export function GetPushTaskList(params) {
  return request({
    params,
    url: "/vas/push/task/pushTaskList",
    method: "get"
  });
}
// 邮件推送-新建更新推送任务
export function NewEditTaskInfo(params) {
  return request({
    data: params,
    url: "/vas/push/task/saveTaskInfo",
    method: "post"
  });
}
// 邮件推送-删除推送任务
export function DeleteTaskInfo(params) {
  return request({
    params,
    url: "/vas/push/task/deleteTaskInfo",
    method: "get"
  });
}
// 邮件推送-获取推送学者人数
export function GetSendMemberSize(params) {
  return request({
    params,
    url: "/vas/push/task/getPreparePushMagHistoryAuthorNumber",
    method: "get"
  });
}
//邮件推送-自助推送
export function TaskSend(params) {
  return request({
    params,
    url: "/vas/push/task/send",
    method: "get"
  });
}
//邮件推送-发送验证码
export function SendReceiverMessage(params) {
  return request({
    params,
    url: "/vas/AlidayuVerifycode/sendReceiverMessage",
    method: "get"
  });
}
//邮件推送-删除每期文章
export function DeleteIssueArticle(params) {
  return request({
    params,
    url: "/VAS/push/task/delete",
    method: "delete"
  });
}
//邮件推送-每期文章排序
export function IssueUpOrBottom(params) {
  return request({
    params,
    url: "/VAS/push/task/sequence",
    method: "get"
  });
}
//邮件推送-上传每期文章封面
export function UploadIssueCover(params) {
  return request({
    params,
    url: "/VAS/push/task/saveCover",
    method: "get"
  });
}

//邮件推送-发件邮箱管理-查询刊物推送邮箱列表
export function GetEmailList(params) {
  return request({
    params,
    url: "/mag/email/list",
    method: "get"
  });
}
//邮件推送-发件邮箱管理-修改本刊任务连续推送天数
export function UpdatePushContinueDay(params) {
  return request({
    params,
    url: "/vas/mag/updatePushContinueDay",
    method: "get"
  });
}
//邮件推送-发件邮箱管理-新增邮箱确定/修改
export function SaveNewEmail(params) {
  return request({
    data: params,
    url: "/mag/email/save",
    method: "post"
  });
}
//邮件推送-发件邮箱管理-删除邮箱
export function DeleteEmail(params) {
  return request({
    params,
    url: "/mag/email/delete",
    method: "get"
  });
}
//邮件推送-发件邮箱管理-邮箱列表上移下移
export function EmailUpOrBottom(params) {
  return request({
    params,
    url: "/mag/email/sequence",
    method: "get"
  });
}
//邮件推送-发件邮箱管理-邮箱列表每个邮箱的收件邮箱列表
export function ExportEmailList(params) {
  return request({
    params,
    url: "/mag/email/email/list",
    method: "get"
  });
}
//邮件推送-发件邮箱管理-邮箱列表每个邮箱的收件邮箱列表删除数据
export function DeleteExportEmailListItem(params) {
  return request({
    params,
    url: "/mag/email/email/delete",
    method: "get"
  });
}
//邮件推送-发件邮箱管理-刊物推送邮箱获取刊物推送作者数
export function Refresh(params) {
  return request({
    params,
    url: "/mag/email/refresh",
    method: "get"
  });
}
//邮件推送-本刊模板-新建保存修改模板
export function SaveEmailModuleSettings(params) {
  return request({
    data: params,
    url: "push/template/save",
    method: "post"
  });
}
//邮件推送-本刊模板-获取模板列表
export function GetEmailModuleList(params) {
  return request({
    params,
    url: "/push/template/list",
    method: "get"
  });
}
//邮件推送-本刊模板-更新模板封面图
export function UpdateTemplateCover(params) {
  return request({
    params,
    url: "/push/template/updateCoverId",
    method: "get"
  });
}
//邮件推送-本刊模板-更新模板封面图
export function DeleteEmailTemplate(params) {
  return request({
    params,
    url: "/push/template/delete",
    method: "get"
  });
}
//邮件推送-本刊模板-更新默认模板
export function UpdateIsDefaulUt(params) {
  return request({
    params,
    url: "/push/template/updateIsDefault",
    method: "get"
  });
}
//邮件推送-任务管理-推送任务更新推送模板
export function UpdateTemplateId(params) {
  return request({
    params,
    url: "/vas/push/task/updateTemplateId",
    method: "get"
  });
}
//邮件推送-基础配置-期刊邮件推送查询
export function GetEmailConfig(params) {
  return request({
    params,
    url: "/push/mag/config/getByMagId",
    method: "get"
  });
}
//邮件推送-基础配置-期刊邮件推送改变
export function UpdateSendCount(params) {
  return request({
    data: params,
    url: "/push/mag/config/update",
    method: "post"
  });
}
//邮件推送-基础配置-学者打开率设置
export function UpdateOpenPercent(params) {
  return request({
    params,
    url: "/push/mag/config/updateOpenPercent",
    method: "get"
  });
}
//邮件推送-预览邮件
export function GetTemplateHtml5Info(params) {
  return request({
    params,
    url: "/vas/push/task/getTemplateHtml5Info",
    method: "get"
  });
}
//邮件推送-学者推送类型设置
export function UpdatePushType(params) {
  return request({
    params,
    url: "/vas/push/task/updatePushType",
    method: "get"
  });
}
//邮件推送-任务管理-推送任务推送学者初始化
export function PreparePushMagHistoryAuthor(params) {
  return request({
    params,
    url: "/vas/push/task/preparePushMagHistoryAuthor",
    method: "get"
  });
}
//邮件推送-推送任务学者分析检查是否需要初始化
export function CheckPreparePushMagHistoryAuthor(params) {
  return request({
    params,
    url: "/vas/push/task/checkPreparePushMagHistoryAuthor",
    method: "get"
  });
}
//邮件推送-推送任务学者分析初始化状态获取
export function CheckPushMagHistoryAuthorTime(params) {
  return request({
    params,
    url: "/vas/push/task/checkPushMagHistoryAuthorTime",
    method: "get"
  });
}
//邮件推送-查询计算目标学者消息提示
export function PreparePushMagHistoryAuthorMessage(params) {
  return request({
    params,
    url: "/vas/push/task/preparePushMagHistoryAuthorMessage",
    method: "get"
  });
}
//邮件推送-自定义推送学者-查询任务自定义推送设置
export function GetCustomScholar(params) {
  return request({
    params,
    url: "/push/set/get",
    method: "get"
  });
}
//邮件推送-自定义推送学者-设置任务自定义推送设置
export function SetCustomScholar(params, id) {
  return request({
    data: params,
    url: `/push/set/updateById?historyId=${id}`,
    method: "post"
  });
}
//邮件推送-自定义推送学者-学者领域tree图
export function GetCategory(params) {
  return request({
    params,
    url: "/vas/push/history/category/categoryTree",
    method: "get"
  });
}
//邮件推送-自定义推送学者-任务推送设置领域
export function SetCategory(params) {
  return request({
    params,
    url: "/vas/push/history/category/set",
    method: "get"
  });
}
//邮件推送-自定义推送学者-设置领域获取回显
export function GetSetCategory(params) {
  return request({
    params,
    url: "/vas/push/history/category/getTreeChoice",
    method: "get"
  });
}
//邮件推送-自定义推送学者-各推送类型默认设置
export function GetPushTypeDefault(params) {
  return request({
    params,
    url: "/push/set/getDefault",
    method: "get"
  });
}
//邮件推送-自定义推送学者-任务默认中图分类id树获取
export function GetDefault(params) {
  return request({
    params,
    url: "/vas/push/history/category/getDefault",
    method: "get"
  });
}
//邮件推送-邮件内容配置-获取推送任务模板类型
export function GetSetType(params) {
  return request({
    params,
    url: "/push//template/getSetType",
    method: "get"
  });
}
//邮件推送-邮件内容配置-获取推送任务模板
export function GetTemplate(params) {
  return request({
    params,
    url: "/push/history/template/getList",
    method: "get"
  });
}
//邮件推送-邮件内容配置-新增每期论文
export function SaveIssueArticle(params) {
  return request({
    data: params,
    url: "/vas/push/article/save",
    method: "post"
  });
}
//邮件推送-查看学者-下载学者状态查询
export function GetDownloadAuthorStatus(params) {
  return request({
    params,
    url: "/vas/exporttask/getByKeyValue",
    method: "get"
  });
}
//邮件推送-查看学者-申请导出预推送学者
export function ApplyDownloadAuthor(params) {
  return request({
    params,
    url: "/vas/push/task/applyExportPushHistoryImportAuthorExcel",
    method: "get"
  });
}
//邮件推送-邮件内容配置-保存推送任务模板
export function SaveTemplate(params) {
  return request({
    data: params,
    url: "/push/history/template/save",
    method: "post"
  });
}
//邮件推送-基础配置-期刊邮件推送期刊选择查询
export function GetMagChioceList(params) {
  return request({
    params,
    url: "/vas/push/mag/mag/set/getChioce",
    method: "get"
  });
}
//邮件推送-基础配置-期刊邮件推送机构选择查询
export function GetOrgChioceList(params) {
  return request({
    params,
    url: "/vas/push/mag/org/set/getChioce",
    method: "get"
  });
}
//邮件推送-基础配置-期刊邮件推送期刊设置
export function SetMagChioce(params) {
  return request({
    params,
    url: "/vas/push/mag/mag/set/set",
    method: "get"
  });
}
//邮件推送-基础配置-期刊邮件推送期刊查询
export function GetMagChioce(params) {
  return request({
    params,
    url: "/vas/push/mag/mag/set/get",
    method: "get"
  });
}
//邮件推送-基础配置-期刊邮件推送机构设置
export function SetOrgChioce(params) {
  return request({
    params,
    url: "/vas/push/mag/org/set/set",
    method: "get"
  });
}
//邮件推送-基础配置-期刊邮件推送机构查询
export function GetOrgChioce(params) {
  return request({
    params,
    url: "/vas/push/mag/org/set/get",
    method: "get"
  });
}
//邮件推送-学者数据测评-整期论文引证分页统计
export function GetIssueArticleCitation(params) {
  return request({
    params,
    url: "/vas/article/getIssueArticleCitation",
    method: "get"
  });
}
//邮件推送-学者数据测评-整期论文引证数量统计
export function SumIssueArticleCitation(params) {
  return request({
    params,
    url: "/vas/article/sumIssueArticleCitation",
    method: "get"
  });
}
//邮件推送-学者数据测评-被引文献
export function GetCountRootArticleCitations(params) {
  return request({
    params,
    url: "/vas/article/countRootArticleCitations",
    method: "get"
  });
}
//邮件推送-学者数据测评-引证期刊
export function GetCountMagCitations(params) {
  return request({
    params,
    url: "/vas/article/countMagCitations",
    method: "get"
  });
}
//邮件推送-学者数据测评-被引机构
export function GetCountRootMagOrg(params) {
  return request({
    params,
    url: "/vas/article/countRootMagOrg",
    method: "get"
  });
}
//邮件推送-学者数据测评-引证机构
export function GetCountCitationsMagOrg(params) {
  return request({
    params,
    url: "/vas/article/countCitationsMagOrg",
    method: "get"
  });
}
//邮件推送-学者数据测评-被引文献关键词
export function GetCountRootArticleKeywords(params) {
  return request({
    params,
    url: "/vas/article/countRootArticleKeywords",
    method: "get"
  });
}
//邮件推送-学者数据测评-引证文献关键词
export function GetCountCitatinsArticleKeywords(params) {
  return request({
    params,
    url: "/vas/article/countCitatinsArticleKeywords",
    method: "get"
  });
}
//邮件推送-邮件内容配置-推送任务添加期刊推送模板
export function SetHistoryTemplate(params) {
  return request({
    params,
    url: "/push/history/template/set",
    method: "get"
  });
}
//邮件推送-邮件内容配置-查询推送任务模板详情
export function GetHistoryTemplateDetail(params) {
  return request({
    params,
    url: "/push/history/template/getById",
    method: "get"
  });
}
//邮件推送-邮件内容配置-查询推送任务模板设置类型
export function GetHistoryTemplateSetType(params) {
  return request({
    params,
    url: "/push/history/template/getSetTypeById",
    method: "get"
  });
}
//邮件推送-邮件内容配置-删除推送任务模板
export function DeleteHistoryTemplate(params) {
  return request({
    params,
    url: "/push/history/template/delete",
    method: "get"
  });
}
//邮件推送-邮件内容配置-更新推送任务模板选中状态
export function UpdateHistoryTemplateChoose(params) {
  return request({
    params,
    url: "/push/history/template/updateIsChoice",
    method: "get"
  });
}
//邮件推送-邮件内容配置-新增推送任务模板
export function AddHistoryTemplate(params) {
  return request({
    params,
    url: "/push/history/template/add",
    method: "get"
  });
}
//邮件推送-学者数据测评分析报告-整期论文引证数量统计
export function GetSumIssueArticleCitation(params) {
  return request({
    params,
    url: "/vas/article/sumIssueArticleCitation",
    method: "get"
  });
}
// 邮件推送-学者数据测评分析报告-高引用期刊（引用次数Top10）学者覆盖情况
export function GetReferenceAuthorCount(params) {
  return request({
    params,
    url: "/vas/statistics/referenceAuthorCount",
    method: "get"
  });
}
// 邮件推送-学者数据测评分析报告-高引证期刊（引证次数Top10）学者覆盖情况
export function GetCitationAuthorCount(params) {
  return request({
    params,
    url: "/vas/statistics/citationAuthorCount",
    method: "get"
  });
}
// 邮件推送-学者数据测评分析报告-本刊发文机构（发文top10）学者储备统计
export function GetInstitutionAuthorCount(params) {
  return request({
    params,
    url: "/vas/statistics/institutionAuthorCount",
    method: "get"
  });
}
// 邮件推送-学者数据测评分析报告-引用机构（引用次数top10）学者储备统计
export function GetReferenceInstitutionAuthorCount(params) {
  return request({
    params,
    url: "/vas/statistics/referenceInstitutionAuthorCount",
    method: "get"
  });
}
// 邮件推送-学者数据测评分析报告-引证机构（引证次数top10）学者储备统计
export function GetCitationInstitutionAuthorCount(params) {
  return request({
    params,
    url: "/vas/statistics/citationInstitutionAuthorCount",
    method: "get"
  });
}
// 邮件推送-学者数据测评分析报告-近五年高被引文献（Top10）的引证文献作者覆盖统计
export function GetcountRootArticleCitationsAuthorCount(params) {
  return request({
    params,
    url: "/vas/article/countRootArticleCitationsAuthorCount",
    method: "get"
  });
}
// 邮件推送-期刊邮件精准推送设置查询
export function GetAccurateConfigList(params) {
  return request({
    params,
    url: "/push/mag/accurate/config/getByMagId",
    method: "get"
  });
}
// 邮件推送-期刊邮件精准推送设置保存
export function SaveAccurateConfig(params) {
  return request({
    data: params,
    url: "/push/mag/accurate/config/save",
    method: "post"
  });
}
// 参考文献分析
export function ReferenceArticleAnalysis(params) {
  return request({
    params,
    url: "/vas/push/task/referenceArticleAnalysis",
    method: "get"
  });
}
// 邮件推送-预览lable标签页
export function getTemplateInfoLabel(params) {
  return request({
    params,
    url: "/vas/push/task/getTemplateInfoLabel",
    method: "get"
  });
}
// 邮件推送-iframe页面
export function getTemplateHtml5InfoAndType(params) {
  return request({
    params,
    url: "/vas/push/task/getTemplateHtml5InfoAndType",
    method: "get"
  });
}
// 邮件推送-更新语言
export function updateLanguage(params) {
  return request({
    params,
    url: "/vas/push/task/updateLanguage",
    method: "get"
  });
}
// 邮件推送-更新链接优先级
export function updateHtmlOrder(params) {
  return request({
    params,
    url: "/vas/push/task/updateHtmlOrder",
    method: "get"
  });
}
// 邮件推送-配置推送期刊机构阈值
export function updatePushMagCustomValue(params) {
  return request({
    params,
    url: "/push/mag/config/updatePushMagCustomValue",
    method: "get"
  });
}
// 邮件推送-查看阈值
export function getSeuilSetting(params) {
  return request({
    params,
    url: "/vas/push/getLimitNumber",
    method: "get"
  });
}
// 推送学者下载
export const exportSendMember =
  PROJECT_CONFIG.BASE_URL + "/vas/common/downloadByRecordId";
// 样例文件下载
export const SampleDownload =
  PROJECT_CONFIG.BASE_URL + "/vas/push/task/importTemplateDownload";
// 样例文件下载
export const DownloadFailRecord =
  PROJECT_CONFIG.BASE_URL + "/vas/push/task/downloadFailRecord";
// 邮件推送文件上传
export const articleUploadUrl =
  PROJECT_CONFIG.BASE_URL + "/vas/push/task/importArticle";
// 官网文章下载
export const exportIssueArticles =
  PROJECT_CONFIG.BASE_URL + "/vas/mag/exportIssueArticles";

// 自定义导入样例文件下载
export const DownloadByCode =
  PROJECT_CONFIG.BASE_URL + "/vas/template/downloadByCode";
// 自定义导入学者上传文件
export const AuthorUploadUrl =
  PROJECT_CONFIG.BASE_URL + "/vas/push/task/importAuthor";
// 自定义推送结果下载
export const DownloadByRecordId =
  PROJECT_CONFIG.BASE_URL + "/vas/common/downloadByRecordId";
// 获取图片路径
export const GetPicturePath =
  PROJECT_CONFIG.BASE_URL + "/vas/push/configManage/getPicturePath";
// 上传微网刊数据
export const articleWeChatUploadUrl =
  PROJECT_CONFIG.BASE_URL + "/vas/push/task/importWeChatArticle";
//邮件推送-发件邮箱管理-导入收件邮箱
export const ImportEmailUploadUrl =
  PROJECT_CONFIG.BASE_URL + "/mag/email/email/importEmail";
//邮件推送-分析报告导出
export const ExportAnalysisFile =
  PROJECT_CONFIG.BASE_URL + "/vas/push/task/exportAnalysisFile";
